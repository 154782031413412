<footer>
    <div class="up-arrow hidden" eid1="56"><i class="xh-icon top"></i></div>
    <div class=" width-wrap">
       <div class="footer-top-part">
          <div class="footer-column__wrapper">
             <div *ngFor="let tag of tags;let i = index" class="footer-column">
                <ul class="column-menu">
                   <li><a href="#">Presse</a></li>
                   <li><a href="#">Blog</a></li>
                   <li><a href="#">Creator's Blog</a></li>
                </ul>
             </div>
          </div>
       </div>
       <div class="footer-bottom-part">
          <div class="footer-column">
             <div class="copyright">© Porn69.vip</div>
          </div>
          <div class="footer-column">
             <a class="xh-button gray square" href="#">
             <i class="xh-icon phone darkest"></i>
             Mobilversion                </a>
          </div>
          <div class="footer-column">
             <div class="socials">
                <a class="twitter" href="#" target="_blank" rel="nofollow noopener noreferrer">
                <i class="xh-icon twitter darkest"></i>
                </a>
                <a class="youtube" href="#" target="_blank" rel="nofollow noopener noreferrer">
                <i class="xh-icon youtube darkest"></i>
                </a>
             </div>
          </div>
          <div class="footer-column">
             <a [routerLink]="['/legal/compliance']" class="compliance">
             18 U.S.C. 2257 Record-Keeping Requirements Compliance Statement                </a>
             <a class="rta" rel="nofollow" [routerLink]="['/legal/compliance']" target="_blank">

             </a>
          </div>
       </div>
    </div>
 </footer>