import { Routes } from '@angular/router';

import { NotFoundComponent } from './not-found.component';


export const APP_ROUTES: Routes = [
  { path: '', loadChildren: () => import('./pages/home/home.module').then(m => m.HomeModule) },
  { path: 'legal/compliance', loadChildren: () => import('./pages/compliance/compliance.module').then(m => m.ComplianceModule) },
  { path: 'category/:code', loadChildren: () => import('./pages/category/category.module').then(m => m.CategoryModule) },
  { path: 'categories', loadChildren: () => import('./pages/category-list/category-list.module').then(m => m.CategoryListModule) },
  { path: 'pornstars', loadChildren: () => import('./pages/pornstar-list/pornstar-list.module').then(m => m.PornstartListModule) },
  { path: 'pornstar/:code', loadChildren: () => import('./pages/pornstar/pornstar.module').then(m => m.PornstarModule) },

  { path: 'tag/:code', loadChildren: () => import('./pages/tag/tag.module').then(m => m.TagModule) },
  { path: 'search/:keyword', loadChildren: () => import('./pages/search/search.module').then(m => m.SearchModule) },
  { path: ':code', loadChildren: () => import('./pages/detail/detail.module').then(m => m.DetailModule) },
  { path: '**', component: NotFoundComponent },
];
