import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { MovieService } from '../service/movies.service';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  // changeDetection: ChangeDetectionStrategy.Default,
})
export class FooterComponent implements OnInit {
  tags: Array<any> = [];
  constructor(
    private movieService: MovieService
  ) {

  }
  ngOnInit() {
    this.initData();
  }
  async initData() {
    const res: any = await this.movieService.getDomainMoreInfo();
    if (!res || !res.data) {
      return;
    }
    const data = res.data;
    if (!data || !data.tags) {
      this.tags = data.tags;
    }
  }
}
